import React, { Component, useEffect, useState } from 'react';
import { array, arrayOf, bool, func, object, shape, number, string, oneOf, } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  getPageURL,
  userDisplayNameAsString,
} from '../../util/data';
import { withSocket, withViewport } from '../../util/contextHelpers';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import {
  Page,
  Modal,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  InlineTextButton,
  Breadcrumbs,
  SocialShare,
  IconShare,
  FinishListing,
  FinishedModal,
  PrimaryButton,
  ResponsiveImage,
  Button,
} from '../../components';
import { FAQForm, EnquiryForm, ListServiceForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionFAQ from './SectionFAQ';
import SectionMapMaybe from './SectionMapMaybe';
import SectionServices from './SectionServices';

import {
  sendEnquiry,
  fetchTimeSlots,
  fetchTransactionLineItems,
  showListing,
} from './ListingPage.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { updateProfile } from '../ProfileSetupPage/ProfileSetupPage.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { closeListing, openListing } from '../ManageListingsPage/ManageListingsPage.duck';
import {
  removeListingImage,
  requestAddAvailabilityException,
  requestClearAvailabilityException,
  requestCreateListing,
  requestDeleteAvailabilityException,
  requestImageUpload,
  updateImageOrder,
} from '../EditListingPage/EditListingPage.duck';

import css from './ListingPage.module.css';
import { findOrCreateChannel } from '../ChatPage/ChatPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      initialValues: {},
      popupModal: false,
      imageCarouselOpen: false,
      isOpenSocialModel: false,
      finishModalOpen: false,
      quoteRequestModalOpen: false,
      quoteRequestMobileModalOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  componentDidMount() {
    const windowDefined = typeof window != 'undefined';
    const listingPopup = windowDefined && window.sessionStorage.getItem('listingPopup');
    this.setState({ popupModal: (listingPopup == this.props.params.id) ? false : true });
  }

  componentDidUpdate() {
    const {
      currentUser,
      getListing,
      getOwnListing,
      params: rawParams,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const { publicData: aPublicData } = (ensuredAuthor && ensuredAuthor.id && ensuredAuthor.attributes.profile) || {};
    const { userType } = aPublicData || {};
    const isOwnListing = userAndListingAuthorAvailable && ensuredAuthor.id.uuid === currentUser.id.uuid;

    if (this.state.popupModal && (isOwnListing || userType == 'planner')) {
      this.setState({ popupModal: false });
    }
  }

  componentWillUnmount() {
    const { onUpdateImageOrder } = this.props;
    onUpdateImageOrder([]);
  }

  handleSubmitForm(values, listingValues = [], action) {
    const { params, onUpdateUserListing, fetchShowListing } = this.props;
    const listingId = new UUID(params.id);

    if (this.state.finishModalOpen == 'FAQ') {
      if (action == 'delete' && this.state.initialValues.index > -1) {
        listingValues = listingValues.filter((lv, ind) => this.state.initialValues.index != ind);
      } else if (this.state.initialValues && this.state.initialValues.index > -1) {
        listingValues[this.state.initialValues.index] = { ...values };
      } else {
        listingValues.push(values);
      }
      return onUpdateUserListing({ publicData: { faqs: listingValues } }).then(() => {
        this.setState({ finishModalOpen: false, initialValues: {} });
        fetchShowListing(listingId);
        if (action == 'another') {
          this.setState({ finishModalOpen: 'FAQ', initialValues: {} });
        }
      });
    } else if (this.state.finishModalOpen == 'SERVICE') {
      if (action == 'delete' && this.state.initialValues.index > -1) {
        listingValues = listingValues.filter((lv, ind) => this.state.initialValues.index != ind);
      } else if (this.state.initialValues && this.state.initialValues.index > -1) {
        listingValues[this.state.initialValues.index] = { ...values };
      } else {
        listingValues.push(values);
      }
      return onUpdateUserListing({ publicData: { services: listingValues } }).then(() => {
        this.setState({ finishModalOpen: false, initialValues: {} });
        fetchShowListing(listingId);
      });
    } else {

    }
  };

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      // onAddOrRemoveToConnected,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser(currentUser, author) {
    const { onSendMessage, history } = this.props;
    return onSendMessage({
      senderId: currentUser.id.uuid,
      receiverId: author.id.uuid
    })
      .then(() => {
        const channelId = [currentUser.id.uuid, author.id.uuid].sort().join('_')
        typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', channelId);
        return history.push(createResourceLocatorString('ChatPage', routeConfiguration(), {}));
      })
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      categories,
      subCategories,
      getListing,
      getOwnListing,
      intl,
      viewport,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      currentUserListing,
      currentUserListingFetched,
      events,
      eventTypes,
      searchListingsError,
      updateInProgress,
      fetchShowListing,
      onOpenListing,
      onCloseListing,
      openingListingInProgress,
      closingListingInProgress,
      currentUserHasListings,


      errors,
      images,
      imageOrder,
      onImageUpload,
      lUpdateInProgress,
      availabilityExceptions,
      fetchExceptionsInProgress,

      onRemoveListingImage,
      onAddAvailabilityException,
      onClearAvailabilityException,
      onDeleteAvailabilityException,
      onCreateListing,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const unattachedImages = imageOrder && imageOrder.length ? imageOrder.map(i => images[i]) : [];
    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const {
      faqs = [],
      services = [],
      availabilityPlan = [],
      categories: lCategories = [],
      location: addressLocation
    } = publicData || {};

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (currentListing && currentListing.id && ['ADMIN', 'EVENT'].includes(currentListing.attributes.publicData.ListingType)) {
      if (['ADMIN'].includes(currentListing.attributes.publicData.ListingType)) {
        return <NamedRedirect name="PurchaseAcornsPage" />;
      }
      return <NamedRedirect name="LandingPage" />;
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({ imageCarouselOpen: true, });
    };

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const { publicData: aPublicData } = (ensuredAuthor && ensuredAuthor.id && ensuredAuthor.attributes.profile) || {};
    const { userType, userListingId } = aPublicData || {};

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const category =
      categories && categories.length && lCategories && lCategories.length && categories.filter(c => c.id == lCategories[0].id).length
        ? categories.filter(c => c.id == lCategories[0].id)[0].label
        : '';
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      {
        category,
        vendorName: authorDisplayName,
        city: addressLocation && addressLocation.address && addressLocation.address.split(',')[0],
        siteTitle,
      }
    );

    // const hostLink = (
    //   <NamedLink
    //     className={css.authorNameLink}
    //     name="ListingPage"
    //     params={params}
    //     to={{ hash: '#host' }}
    //   >
    //     {authorDisplayName}
    //   </NamedLink>
    // );

    const PageUrl = getPageURL();

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.maxSizePage}>
              <div className={css.listingPageWrapper}>
                <div className={css.mainWrapperLeft}>
                  <div className={css.topbarSection}>
                    <div>
                      <Breadcrumbs />
                    </div>
                    {isMobileLayout
                      ? null
                      : <div>
                        {/* {showContactUser && currentUser && currentUser.id ? (
                          <span className={css.contactWrapper}>
                            <InlineTextButton
                              rootClassName={css.contactLink}
                              onClick={() => this.onContactUser(currentUser, currentAuthor)}
                              enforcePagePreloadFor="SignupPage"
                            >
                              <IconShare type="message" />
                              <FormattedMessage id="ListingPage.contactUser" />
                            </InlineTextButton>
                          </span>
                        ) : null} */}
                        <span className={css.contactWrapper}>
                          <InlineTextButton
                            rootClassName={css.contactLink}
                            onClick={() => this.setState({ isOpenSocialModel: true })}
                            enforcePagePreloadFor="SignupPage"
                          >
                            <IconShare type="share" />
                            <FormattedMessage id="ListingPage.shareUser" />
                          </InlineTextButton>
                        </span>
                      </div>}
                  </div>
                  <SectionImages
                    title={title}
                    categories={categories}
                    subCategories={subCategories}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                    imageCarouselOpen={this.state.imageCarouselOpen}
                    onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  <div className={css.contentContainerBox}>
                    <SectionAvatar user={currentAuthor} params={params} />
                    {/* <div className={css.wishlistBox}>
                      <IconShare type="like" />
                      </div> */}
                    {/* <SocialShare
                    isPlain={true}
                    url={PageUrl}
                    className={css.socialShare}
                    onClose={() => this.setState({ isOpenSocialModel: false })}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}

                    <SectionHeading
                      reviews={reviews}
                      publicData={publicData}
                    />
                    <div className={css.mainContent}>
                      <SectionDescriptionMaybe richTitle={richTitle} description={description} shareModalOpen={() => this.setState({ isOpenSocialModel: true })}
                        currentUser={currentUser}
                        author={currentAuthor}
                        onContactUser={this.onContactUser}
                        history={history}
                        onSendMessage={this.props.onSendMessage}
                      />
                      <SectionServices
                        services={services}
                        isOwnListing={isOwnListing}
                        fetchServicesError={searchListingsError}
                        setInitialValues={v => this.setState({ initialValues: v })}
                        setFinishModalOpen={v => this.setState({ finishModalOpen: v })}
                      />
                      <hr className={css.dividerLine} />
                      <SectionMapMaybe
                        userType={userType}
                        publicData={publicData}
                        geolocation={geolocation}
                        isOwnListing={isOwnListing}
                        listingId={currentListing.id}
                      />
                      <SectionFeaturesMaybe
                        publicData={publicData}
                        isOwnListing={isOwnListing}
                        availabilityPlan={availabilityPlan}
                        intl={intl}
                      />
                      {/* <hr className={css.dividerLine} />
                      <SectionInstagram
                        instagramPost={reviews}
                        fetchInstagramError={fetchReviewsError}
                      />
                      <hr className={css.dividerSocial} />
                      <SectionReviews
                        reviews={reviews}
                        fetchReviewsError={fetchReviewsError}
                      /> */}
                      <SectionFAQ
                        faqs={faqs}
                        isOwnListing={isOwnListing}
                        setFinishModalOpen={v => this.setState({ finishModalOpen: v })}
                        setInitialValues={v => this.setState({ initialValues: v })}
                      />

                    </div>
                    {isMobileLayout
                      ? <PrimaryButton
                        onClick={() => this.setState({ quoteRequestMobileModalOpen: true })}>
                          {intl.formatMessage({ id: "ListingPage.RequestQuoteButton" })}
                      </PrimaryButton>
                      : null}
                  </div>
                </div>

                {['vendor', 'venue'].includes(userType) || isOwnListing
                  ? <div className={css.bookingPanelBox}>
                    <BookingPanel
                      className={css.bookingPanelSection}
                      userType={userType}
                      listing={currentListing}
                      currentUserHasListings={currentUserHasListings}
                      isAuthenticated={isAuthenticated}
                      currentUserListing={currentUserListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      onSubmit={handleBookingSubmit}
                      title={bookingTitle}
                      onOpenListing={() => onOpenListing(listingId).then(() => fetchShowListing(listingId))}
                      onCloseListing={() => onCloseListing(listingId).then(() => fetchShowListing(listingId))}
                      openingListingInProgress={openingListingInProgress}
                      closingListingInProgress={closingListingInProgress}
                      setFinishModalOpen={value => this.setState({ finishModalOpen: value })}
                      quoteRequestModalOpen={this.state.quoteRequestModalOpen}
                      quoteRequestMobileModalOpen={this.state.quoteRequestMobileModalOpen}
                      setQuoteRequestMobileModalClose={() => this.setState({ quoteRequestMobileModalOpen: false })}
                      setQuoteRequestModalOpen={(e) => this.setState({ quoteRequestModalOpen: e })}
                      authorDisplayName={authorDisplayName}
                      onManageDisableScrolling={onManageDisableScrolling}
                      monthlyTimeSlots={monthlyTimeSlots}
                      onFetchTimeSlots={onFetchTimeSlots}
                      onFetchTransactionLineItems={onFetchTransactionLineItems}
                      lineItems={lineItems}
                      fetchLineItemsInProgress={fetchLineItemsInProgress}
                      fetchLineItemsError={fetchLineItemsError}
                      errors={errors}
                      currentUser={currentUser}
                      images={unattachedImages}
                      categories={lCategories}
                      onImageUpload={onImageUpload}
                      updateInProgress={lUpdateInProgress}
                      onRemoveListingImage={onRemoveListingImage}
                      availabilityExceptions={availabilityExceptions}
                      fetchExceptionsInProgress={fetchExceptionsInProgress}
                      onAddAvailabilityException={onAddAvailabilityException}
                      onClearAvailabilityException={onClearAvailabilityException}
                      onDeleteAvailabilityException={onDeleteAvailabilityException}
                      onCreateListing={onCreateListing}
                      eventTypes={eventTypes}
                    />
                  </div>
                  : null}

              </div>
            </div>

            <SocialShare
              isOpen={this.state.isOpenSocialModel}
              onClose={() => this.setState({ isOpenSocialModel: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              url={PageUrl}
              publicData={publicData}
            />

            <Modal
              id="ListingPage.finish"
              fullWidth={this.state.finishModalOpen == 'REQUEST_SENT'}
              contentClassName={css.finishModalContent}
              isOpen={!!this.state.finishModalOpen}
              onClose={() => this.setState({ finishModalOpen: false, initialValues: {} })}
              onManageDisableScrolling={onManageDisableScrolling}
              className={css.finishModal}
            >
              {this.state.finishModalOpen == 'FAQ' ? (
                <FAQForm
                  initialValues={this.state.initialValues}
                  onSubmit={values => this.handleSubmitForm(values, faqs)}
                  onSaveAndAddAnother={values => this.handleSubmitForm(values, faqs, 'another')}
                  onDelete={values => this.handleSubmitForm(values, faqs, 'delete')}
                />
              ) : this.state.finishModalOpen == 'SERVICE' ? (
                <ListServiceForm
                  images={images}
                  services={services}
                  subCategories={subCategories}
                  currentUserId={currentUser && currentUser.id && currentUser.id.uuid}
                  initialValues={this.state.initialValues}
                  onSubmit={values => this.handleSubmitForm(values, services)}
                  onDelete={values => this.handleSubmitForm(values, services, 'delete')}
                  updateInProgress={updateInProgress}
                />
              )
                : null}
            </Modal>

            <FinishedModal
              id="finishedModal"
              contentClassName={css.finishedModalContent}
              isOpen={this.state.finishModalOpen == "REQUEST_SENT"}
              onClose={() => this.setState({ finishModalOpen: false, initialValues: {} })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <FinishListing
                finishEvent={'REQUEST'}
                currentUserListing={currentListing}
                state={this.state.finishModalOpen}
                comingFrom={'listing'}
                heading={
                  isAuthenticated ? (
                    <span><FormattedMessage id={'ListingPage.loggedHeading'} /></span>
                  ) : (
                    <FormattedMessage id={'ListingPage.heading'} />
                  )
                }
                subHeading={
                  isAuthenticated ? (
                    <FormattedMessage id={'ListingPage.loggedSubHeading'} />
                  ) : (
                    <FormattedMessage id={'ListingPage.subHeading'} />
                  )
                }
                secondaryButtonText={''}
                //   isAuthenticated ? (
                //     ''
                //   ) : (
                //     <FormattedMessage id={'ListingPage.loggedSecondaryButtonText'} />
                //   )
                // }
                primaryButtonText={
                  isAuthenticated ? (
                    <FormattedMessage id={'ListingPage.loggedPrimaryButtonText'} />
                  ) : (
                    <FormattedMessage id={'ListingPage.primaryButtonText'} />
                  )
                }
                anchorText={isAuthenticated && <FormattedMessage id={'ListingPage.noThankYou'} />}
              />
            </FinishedModal>

            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
            <Modal
              id="ListingPage.enquiry1"
              className={css.TripIdeaModal}
              isOpen={this.state.popupModal}
              onClose={() => [window.sessionStorage.setItem('listingPopup', rawParams.id), this.setState({ popupModal: false })]}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.tripModalContent}>
                <div className={css.profileImgModal}>
                  {/* <SectionAvatar user={currentAuthor} params={params} /> */}

                  <ResponsiveImage
                    image={currentAuthor.profileImage}
                    alt={"img"}
                    variants={['square-small', 'square-small2x']}
                  />
                </div>
                <h2>
                  <FormattedMessage id={"ListingPage.popupModalHeading"} />
                </h2>
                <p>
                  <FormattedMessage id={"ListingPage.popupModalSummary"} />
                </p>
                <Button
                  type={'button'}
                  onClick={() => [window.sessionStorage.setItem('listingPopup', rawParams.id), this.setState({ popupModal: false })]}
                >
                  <FormattedMessage id={"ListingPage.popupModalButton"} />
                </Button>
                <br />

                {/* <input type="checkbox" id="don't show" name="don't show" value="don't show" />
                <label htmlFor="don't show">&nbsp;&nbsp;Please don't show me again</label> */}
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const {
    errors,
    images,
    imageOrder,
    updateInProgress: lUpdateInProgress,
    availabilityExceptions,
    fetchExceptionsInProgress,
  } = state.EditListingPage;
  const {
    updateInProgress,
  } = state.ProfileSetupPage;
  const { currentPageResultIds, searchListingsError } = state.SearchPage;
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    eventTypes,
    categories,
    subCategories,
    currentUserHasListings
  } = state.user;
  const { openingListingInProgress, closingListingInProgress } = state.ManageListingsPage;

  const pageListings = getListingsById(state, currentPageResultIds);
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    eventTypes,
    categories,
    subCategories,
    updateInProgress,
    lUpdateInProgress,
    openingListingInProgress,
    closingListingInProgress,
    events: pageListings,
    searchListingsError,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasListings,

    images,
    errors,
    imageOrder,
    availabilityExceptions,
    fetchExceptionsInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onUpdateUserListing: LPayload => dispatch(updateProfile(null, LPayload)),
  fetchShowListing: (listingId) => dispatch(showListing(listingId)),
  onOpenListing: (listingId) => dispatch(openListing(listingId)),
  onCloseListing: (listingId) => dispatch(closeListing(listingId)),
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onRemoveListingImage: imageId => dispatch(removeListingImage(imageId)),
  onAddAvailabilityException: params => dispatch(requestAddAvailabilityException(params)),
  onClearAvailabilityException: () => dispatch(requestClearAvailabilityException()),
  onDeleteAvailabilityException: params => dispatch(requestDeleteAvailabilityException(params)),
  onCreateListing: values => dispatch(requestCreateListing(values)),
  onSendMessage: params => dispatch(findOrCreateChannel(params)),
  onUpdateImageOrder: params => dispatch(updateImageOrder(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withSocket,
  injectIntl
)(ListingPageComponent);

export default ListingPage;
